import { Component } from "@angular/core";

@Component({
  selector: "app-home-main-slider",
  templateUrl: "./home-main-slider.component.html",
  styleUrls: ["./home-main-slider.component.scss"],
})
export class HomeMainSliderComponent {
  title = "ngSlick";

  slides = [
    {
      link: "/grailer3",
      linkbuy: "knivesandtools",
      component: "Grailer3Component",
      title: "The new Grailer 3",
      subtitle:
        "A lightweight and characteristic EDC knife for all your daily tasks.",
      img: "/assets/img/g3-hero-main.jpg",
      imgmob: "/assets/img/g3-hero-main-mob.jpg",
      //buy: "inline-block",
      buy: "none",
    },

    {
      link: "/grailer2",
      component: "Grailer2Component",
      title: "The Grailer 2",
      subtitle: "An exquisite gentleman’s folder and premium EDC knife in one.",
      img: "/assets/img/g2-hero-main.jpg",
      imgmob: "/assets/img/g2-hero-main-mob.jpg",
      buy: "none",
    },
    {
      link: "/grailer1",
      component: "Grailer1Component",
      title: "The Grailer 1",
      subtitle:
        "The desire to create an exquisite knife, the start of an adventure.",
      img: "/assets/img/g1-hero-main.jpg",
      imgmob: "/assets/img/g1-hero-main-mob.jpg",
      buy: "none",
    },
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  slickInit(e) {
    console.log("slick initialized");
  }

  breakpoint(e) {
    console.log("breakpoint");
  }

  constructor() {}
}
