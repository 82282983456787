import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { VgCoreModule } from "videogular2/compiled/core";
import { VgControlsModule } from "videogular2/compiled/controls";

import { SlickCarouselModule } from "ngx-slick-carousel";
import { NgxParallaxScrollModule } from "ngx-parallax-scroll";
import { CarouselModule } from "ngx-owl-carousel-o";

import { ContentService } from "./shared/services/content.service";
import { ExternalUrlDirective } from "./external-url.directive";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { HomeMainSliderComponent } from "./shared/components/home-main-slider/home-main-slider.component";
import { HeaderComponent } from "./shared/components/header/header.component";
import { HeaderNavigationComponent } from "./shared/components/header-navigation/header-navigation.component";
import { HeaderLogoComponent } from "./shared/components/header-logo/header-logo.component";
import { HomeIntroComponent } from "./shared/components/home-intro/home-intro.component";
import { SliderGalleryComponent } from "./shared/components/g2/slider-gallery/slider-gallery.component";
import { DesignerComponentG2 } from "./shared/components/g2/designer-g2/designer.component";
import { GrailerTwoComponent } from "./pages/grailer-two/grailer-two.component";
import { HeroG2Component } from "./shared/components/g2/hero-g2/hero-g2.component";
import { IntroG2Component } from "./shared/components/g2/intro-g2/intro-g2.component";
import { VideoG2Component } from "./shared/components/g2/video-g2/video-g2.component";
import { ParallaxLogoComponent } from "./shared/components/parallax-logo/parallax-logo.component";
import { HomeKnivesComponent } from "./shared/components/home-knives/home-knives.component";
import { AboutComponent } from "./pages/about/about.component";
import { AboutHeroComponent } from "./shared/components/about-hero/about-hero.component";
import { FeatureAComponent } from "./shared/components/feature-a/feature-a.component";
import { FeatureBComponent } from "./shared/components/feature-b/feature-b.component";
import { FeatureCComponent } from "./shared/components/feature-c/feature-c.component";
import { FeatureDComponent } from "./shared/components/feature-d/feature-d.component";
import { FeatureEComponent } from "./shared/components/feature-e/feature-e.component";
import { HeroDesignerG2Component } from "./shared/components/g2/designer-g2/hero-designer-g2/hero-designer-g2.component";
import { SpecificationsG2Component } from "./shared/components/g2/specifications-g2/specifications-g2.component";
import { GrailerOneComponent } from "./pages/grailer-one/grailer-one.component";
import { HeroG1Component } from "./shared/components/g1/hero-g1/hero-g1.component";
import { IntroG1Component } from "./shared/components/g1/intro-g1/intro-g1.component";
import { SpecificationsG1Component } from "./shared/components/g1/specifications-g1/specifications-g1.component";
import { SliderGalleryG1Component } from "./shared/components/g1/slider-gallery-g1/slider-gallery-g1.component";
import { DesignerG1Component } from "./shared/components/g1/designer-g1/designer-g1.component";
import { HeroDesignerG1Component } from "./shared/components/g1/hero-designer-g1/hero-designer-g1.component";
import { FeatureB1Component } from "./shared/components/feature-b1/feature-b1.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { GrailerThreeComponent } from "./pages/grailer-three/grailer-three.component";
import { HeroG3Component } from "./shared/components/g3/hero-g3/hero-g3.component";
import { IntroG3Component } from "./shared/components/g3/intro-g3/intro-g3.component";
import { SpecificationsG3Component } from "./shared/components/g3/specifications-g3/specifications-g3.component";
import { SliderGalleryG3Component } from "./shared/components/g3/slider-gallery-g3/slider-gallery-g3.component";
import { DesignerG3Component } from "./shared/components/g3/designer-g3/designer-g3.component";
import { HeroDesignerG3Component } from "./shared/components/g3/designer-g3/hero-designer-g3/hero-designer-g3.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeMainSliderComponent,
    HeaderComponent,
    HeaderNavigationComponent,
    HeaderLogoComponent,
    HomeIntroComponent,
    SliderGalleryComponent,
    DesignerComponentG2,
    GrailerTwoComponent,
    HeroG2Component,
    IntroG2Component,
    VideoG2Component,
    ParallaxLogoComponent,
    HomeKnivesComponent,
    AboutComponent,
    AboutHeroComponent,
    FeatureAComponent,
    FeatureBComponent,
    FeatureCComponent,
    FeatureDComponent,
    FeatureEComponent,
    HeroDesignerG2Component,
    SpecificationsG2Component,
    GrailerOneComponent,
    HeroG1Component,
    IntroG1Component,
    SpecificationsG1Component,
    SliderGalleryG1Component,
    DesignerG1Component,
    HeroDesignerG1Component,
    FeatureB1Component,
    FooterComponent,
    ContactComponent,
    GrailerThreeComponent,
    HeroG3Component,
    IntroG3Component,
    SpecificationsG3Component,
    SliderGalleryG3Component,
    DesignerG3Component,
    HeroDesignerG3Component,
    ExternalUrlDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
    VgCoreModule,
    VgControlsModule,
    NgxParallaxScrollModule,
    CarouselModule,
  ],
  providers: [ContentService],
  bootstrap: [AppComponent],
})
export class AppModule {}
