import { InjectionToken, NgModule } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { GrailerThreeComponent } from "./pages/grailer-three/grailer-three.component";
import { GrailerTwoComponent } from "./pages/grailer-two/grailer-two.component";
import { GrailerOneComponent } from "./pages/grailer-one/grailer-one.component";
import { AboutComponent } from "./pages/about/about.component";
import { ContactComponent } from "./pages/contact/contact.component";

const externalUrlProvider = new InjectionToken("externalUrlRedirectResolver");
const deactivateGuard = new InjectionToken("deactivateGuard");

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", pathMatch: "full", component: HomeComponent },
  { path: "grailer3", pathMatch: "full", component: GrailerThreeComponent },
  { path: "grailer2", pathMatch: "full", component: GrailerTwoComponent },
  { path: "grailer1", pathMatch: "full", component: GrailerOneComponent },
  { path: "about", pathMatch: "full", component: AboutComponent },
  { path: "contact", pathMatch: "full", component: ContactComponent },
  {
    path: "externalRedirect",
    canActivate: [externalUrlProvider],
    component: HomeComponent,
  },

  { path: "**", component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get("externalUrl");
        window.open(externalUrl, "_blank");
      },
    },
    {
      provide: deactivateGuard,
      useValue: () => {
        console.log("Guard function is called!");

        return false;
      },
    },
  ],
})
export class AppRoutingModule {}
